
const InfoCard : React.FC<{title: string, content: string[], extraClass?: string}> = ({title, content, extraClass}) => {
    return (
        <div className={`rounded-lg shadow-md bg-gradient-to-b from-blue-400 to-blue-600 p-4 text-white w-64 ${extraClass}`}>
            {/* Title */}

            <h3 className="text-center text font-bold mb-3">{title}</h3>
            {/* Content */}
            <div className="text-xs space-y-2 bottom-0">
                {content.map((item, index) => (
                    <p className="text-center" key={index}>{item}</p>
                ))}
            </div>
        </div>
    );
};

export default InfoCard;