import Layout from "../../Layout/Layout.component";
import { AppContext } from "../../Context/MainContext";
import { useContext, useEffect, useState } from "react";
import { getProjectResume } from "../../utils/planification.utils";
import DataTable from "react-data-table-component";
import { formatCurrency } from "../../utils/number.utils";

const PlanificationDetailPage = () => {
    const { transactions, planifications } = useContext(AppContext);
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        if (planifications.length > 0) {
            let datum = getProjectResume(planifications, transactions);
            setData(datum);
        }
    }, [planifications, transactions]);

    let cols = [
        { width: "7%", name: "Semana", selector: (row: any) => row.index, sortable: true },
        { name: <div>Fecha de inicio</div>, selector: (row: any) => new Date(row.startDate).toLocaleDateString(), sortable: true, sortFunction: (a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime() },
        { name: <div>Fecha de fin</div>, selector: (row: any) => new Date(row.endDate).toLocaleDateString(), sortable: true, sortFunction: (a: any, b: any) => new Date(a.endDate).getTime() - new Date(b.endDate).getTime() },
        { name: <div>Valor planificado</div>, selector: (row: any) => `$${formatCurrency(row.planifiedEarnValue)}`, sortable: true, sortFunction: (a: any, b: any) => parseInt(a.planifiedEarnValue) - parseInt(b.planifiedEarnValue) },
        { name: <div>V.P Acumulado</div>, selector: (row: any) => `$${formatCurrency(row.accPlanifiedEarnValue)}`, sortable: true, sortFunction: (a: any, b: any) => parseInt(a.accPlanifiedEarnValue) - parseInt(b.accPlanifiedEarnValue) },
        { name: <div>Valor ganado</div>, selector: (row: any) => `$${formatCurrency(row.earnValue)}`, sortable: true, sortFunction: (a: any, b: any) => parseInt(a.earnValue) - parseInt(b.earnValue) },
        { name: <div>V.G Acumulado</div>, selector: (row: any) => `$${formatCurrency(row.accEarnValue)}`, sortable: true, sortFunction: (a: any, b: any) => parseInt(a.accEarnValue) - parseInt(b.accEarnValue) },
        { name: <div>Costo</div>, selector: (row: any) => `$${formatCurrency(row.costValue)}`, sortable: true, sortFunction: (a: any, b: any) => parseInt(a.costValue) - parseInt(b.costValue) },
        { name: <div>Costo Acum.</div>, selector: (row: any) => `$${formatCurrency(row.accCostValue)}`, sortable: true, sortFunction: (a: any, b: any) => parseInt(a.accCostValue) - parseInt(b.accCostValue) },
        { name: <div>EEPP</div>, selector: (row: any) => `$${formatCurrency(row.EEPP)}`, sortable: true, sortFunction: (a: any, b: any) => parseInt(a.accEEPP) - parseInt(b.accEEPP) },
        { name: <div>EEPP Acumulado</div>, selector: (row: any) => `$${formatCurrency(row.accEEPP)}`, sortable: true, sortFunction: (a: any, b: any) => parseInt(a.accEEPP) - parseInt(b.accEEPP) },
    ];

    return (
        <Layout>
            <div className="container">
                <h1 className="text-2xl font-bold mb-4">Detalle de planificación</h1>
                <DataTable
                    columns={cols}
                    data={data}
                    defaultSortFieldId={1}
                    paginationComponentOptions={{
                        rowsPerPageText: "Filas por página",
                        rangeSeparatorText: "de",
                        selectAllRowsItem: true,
                        selectAllRowsItemText: "Todos",
                    }}
                    fixedHeader
                />
            </div>
        </Layout>
    );
};

export default PlanificationDetailPage;

