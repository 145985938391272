export const getProjectResume = (planifications: any[], transactions: any[]) => {
    let accSpends = 0;
    let accEEPP = 0;
    let accEarnValue = 0;
    let accPlanifiedEarnValue = 0;
    let accWorkloads = 0;
    let accInvoices = 0;

    let datum = planifications
        .sort((a: any, b: any) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime())
        .map((planification: any, index: number) => {

            let startDate = new Date(planification.startDate);
            let endDate = new Date(planification.endDate);

            let transactionsInPeriod = transactions.filter((transaction: any) => {
                let transactionDate = new Date(transaction.date);
                return transactionDate >= startDate && transactionDate <= endDate;
            });

            let invoiceTotal = transactionsInPeriod
                .filter((transaction: any) => transaction.type === 'FACTURA')
                .filter((transaction: any) => !transaction.status.toLowerCase().includes('rechazada'))
                .reduce((acc: number, transaction: any) => acc + transaction.total, 0);

            let EEPP = transactionsInPeriod
                .filter((transaction: any) => transaction.type === 'EEPP')
                .reduce((acc: number, transaction: any) => acc + transaction.total, 0);

            let workloads = planification.workloadValues.reduce((acc: number, wl: any) => acc + wl.value, 0);
            let costValue = invoiceTotal + workloads;
            accWorkloads += workloads;
            accInvoices += invoiceTotal;
            accSpends += costValue;
            accEEPP += EEPP;
            accEarnValue += planification.earnValue;
            accPlanifiedEarnValue += planification.planifiedEarnValue;

            planification.accPlanifiedEarnValue = accPlanifiedEarnValue;
            planification.accEarnValuePercentage = (accEarnValue / accPlanifiedEarnValue) * planification.planifiedEarnValuePercentage;
            planification.workload = workloads;
            planification.invoiceTotal = accInvoices;
            planification.accWorkloads = accWorkloads;
            planification.accEarnValue = accEarnValue;
            planification.costValue = costValue;
            planification.accCostValue = accSpends;
            planification.accEEPP = accEEPP;
            planification.EEPP = EEPP;
            planification.index = index + 1;

            return planification;
        });
    return datum;
}

export const getSpendsByControlSheet = (transactions: any[], controlSheet: string | null) => {
    let spends = transactions
        .filter((transaction: any) => transaction.type === 'FACTURA' && transaction.status.toLowerCase().includes('aprobada'))
        .filter((transaction: any) => transaction.controlSheet === controlSheet)
        .reduce((acc: any, transaction: any) => {
            acc += transaction.total;
            return acc;
        }, 0);
    return spends;
}

export const getSpendsByFamily = (transactions: any[], planifications: any[], workloads: any[], family: string | null) => {
    let spends = transactions
        .filter((transaction: any) => transaction.type === 'FACTURA' && transaction.status.toLowerCase().includes('aprobada'))
        .filter((transaction: any) => transaction.family === family)
        .reduce((acc: any, transaction: any) => {
            acc += transaction.total;
            return acc;
        }, 0);
    let workloadNames = workloads.filter((workload: any) => workload.family === family).map((workload: any) => workload.name);
    let workloadSpends = planifications
        .map((planification: any) => {
            let workload = planification.workloadValues.find((workload: any) => workloadNames.includes(workload.name));
            return workload ? parseInt(workload.value) : 0;
        })
        .reduce((acc: any, value: any) => acc + value, 0);
    spends += workloadSpends;
    return spends;
}


export const getSpendsByFamilies = (transactions: any[], planifications: any[], project: any) => {
    let res: any = {};
    project.families.map((family: any) => {
        let spends = getSpendsByFamily(transactions, planifications, project.workload, family.name);
        res[family.name] = spends;
    });
    res["Sin familia"] = getSpendsByFamily(transactions, planifications, project.workload, null);

    return res;
}

export const getSpendsByWorkload = (planifications: any[], project: any) => {
    let res: any = {};
    let workloadNames = project.workload.map((workload: any) => workload.name);
    let spends = planifications
        .map((planification: any) =>  planification.workloadValues)
        .reduce((acc: any, workloads: any) => {
            workloads.map((workload: any) => {
                if (workloadNames.includes(workload.name)) {
                    acc[workload.name] = acc[workload.name] ? acc[workload.name] + parseInt(workload.value) : parseInt(workload.value);
                }
            });
            return acc;
        }, {});
    return spends;
}



export const getControlSheetResumes = (transactions: any[], controlSheets: any[]) => {
    let res: any = controlSheets.map((controlSheet: any) => {
        let spends = getSpendsByControlSheet(transactions, controlSheet.name);
        res.push({
            ...controlSheet,
            spends: spends
        });
    });
    return res;
}

